import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const mdTheme = createTheme({
  components: {
  },
  direction: 'ltr',
  typography: {
    fontFamily: '"Montserrat"',
  },
  palette: {
    background: {
      default: "#e8e8e6"
    },
    primary: {
      main: '#cc9764',
    },
    secondary: {
      main: '#cc9764',
    },
    error: {
      main: red.A400,
    },
  },
});

export default mdTheme;
