import * as React from "react";
import classes from "./Terms.module.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import mdTheme from "../../../theme";

function TermsContent() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <section className={classes.termsContainer}>
          <h1>
            "HEAR OUR VOICES – RESILIENCE UNDER FIRE" WEBSITE TERMS OF USE
          </h1>
          <p>
            Welcome to https://hearourvoices.io (together with its subdomains,
            Content, Marks and services, each as defined below, the “Site”).
            Please read the following Terms of Use (the <b> “Agreement”</b>)
            carefully before using this Site so that you are aware of your legal
            rights and obligations with respect to Hear Our Voices – Resilience
            Under Fire (together with its organizers and agents,
            <b>“Hear Our Voices – Resilience Under Fire” </b>, <b>“we”</b>,{" "}
            <b>“our”</b> or <b>“us”</b>). By accessing or using the Site, you
            expressly acknowledge and agree that you are entering a legal
            agreement with us and have understood and agree to comply with, and
            be legally bound by, this Agreement, together with the Privacy
            Policy, https://www.hearourvoices.io/privacy, which is hereby
            incorporated into this Agreement by reference (the date of such
            acceptance, the “Effective Date”). You hereby waive any applicable
            rights to require an original (non-electronic) signature or delivery
            or retention of non-electronic records, to the extent not prohibited
            under applicable law. If you do not agree to be bound by this
            Agreement, please do not access or use the Site.{" "}
          </p>
          <p>
            "Hear Our Voices – Resilience Under Fire" is a musical fundraising
            event, scheduled for October 29, 2023, at the Tel Aviv Museum of Art
            ("the <b>Event</b>"). The fundraising event will serve as a platform
            to raise funds for charity in Israel, which was affected by the
            tragedies starting October 7, 2023, and as an opportunity to bring
            people together in the spirit of kindness, bringing back a little
            bit of the gentleness and human spirit we all need right now. The
            concert in Tel Aviv will be digitally broadcast internationally,
            simultaneously uniting the local musical artists with other singers
            around the world.{" "}
          </p>

          <p>
            The Site is an internet platform for promoting, donating, and
            watching the event and videos from the event. The donations will be
            made via JGive{" "}
            <a style={{color: "#cc9764"}} href="https://www.jgive.com/new/en/usd">
              https://www.jgive.com/new/en/usd
            </a>
            or other third-party platforms designated by us to collect donations
            for the Event (the <b>"Donation Platforms"</b>). Therefore, should
            you decide to donate to the Event, the terms of use and privacy
            policy of each Donation Platform will apply, and you are encouraged
            to read their Terms of Use and Privacy Policy as well.
          </p>
          <ol>
            <li>
              <b>Modification.</b> We reserve the right, at our discretion, to
              modify this Agreement at any time. Such modification will be
              effective immediately following posting of the revised Agreement
              on the Site. You should continue checking for changes by clicking
              on the “Terms of Use” hypertext link located at the bottom of our
              web pages from time to time. Your continued use of the Site
              thereafter means that you accept those modifications.
            </li>
            <li>
              <b>Ability to Accept.</b> The Site is only intended for
              individuals aged eighteen (18) years or older, or individuals
              younger than eighteen (18) years old with adult supervision. If
              you are under 18 years old, please do not visit or use the Site
              unless you have such supervision.{" "}
            </li>
            <li>
              <b>Donations.</b> The Event is a fundraiser for charities in
              Israel. We would like to thank you for your generosity!! By
              donating through us, you acknowledge that the charities to which
              you donate are at our sole discretion, and we are allowed to
              decide to which entities we would contribute the donation and the
              amount to each charity. We guarantee that all charities chosen by
              us are due to their relationship to Israel, the tragic events that
              occurred in October 2023, and the subsequent war (until its
              conclusion). For more information, feel free to reach out to us at
              hearourvoicesIL@gmail.com.
            </li>
            <li>
              <b>Site Access.</b> For such time as this Agreement is in effect,
              we hereby grant you a personal, limited, non-exclusive,
              non-assignable, non-sublicensable, revocable right to access and
              use the Site solely for your own personal and non-commercial use,
              and provided that you comply with this Agreement. Except for the
              foregoing right, we do not grant you any right or licenses to any
              of our or a third party’s Intellectual Property Rights.
            </li>
            <li>
              <b>Restrictions.</b> As a condition to your right to access and
              use the Site, you shall not (and shall not permit or encourage any
              third party to) do any of the following: (a) copy or reproduce the
              Site or any of the artwork or information from the Event uploaded
              to the Site; (b) sell, assign, lease, lend, rent, distribute, or
              make available the Site to any third party, or otherwise offer or
              use the Site in a time-sharing, outsourcing, or service bureau
              environment; (c) modify, alter, adapt, arrange, translate,
              decompile, disassemble, reverse engineer, decrypt, or otherwise
              attempt to discover the source code or non-literal aspects (such
              as the underlying ideas, algorithms, structure, sequence,
              organization, and interfaces) of the Site; (d) remove, alter, or
              conceal, in whole or in part, any copyright, trademark, or other
              proprietary rights notice or legend displayed or contained on or
              in the Site; (e) circumvent, disable or otherwise interfere with
              security-related or technical features or protocols of the Site;
              (f) make a derivative work of the Site, or use the Site to develop
              any service or product that is the same as (or substantially
              similar to or competitive with) the Site; (g) publish or transmit
              any robot, virus, malware, Trojan horse, spyware, or similar
              malicious item intended (or that has the potential) to damage or
              disrupt the Site; (h) take any action that imposes or may impose
              (at our sole discretion) an unreasonable or disproportionately
              large load on the Site infrastructure, or otherwise interfere (or
              attempt to interfere) with the integrity or proper working of the
              Site; and/or (i) use the Site to infringe, misappropriate or
              violate any third party's Intellectual Property Rights (as defined
              below), or any law.
            </li>
            <li>
              <b>No Payments to Hear Our Voices – Resilience Under Fire.</b>{" "}
              Your right to access and use the Site is free, but Hear Our Voices
              – Resilience Under Fire may, in the future, charge a fee (which
              will be donated to charity as well) for certain access or usage.
              You will not be charged for any such access or use of the Site
              unless you first agree to such charges, but please be aware that
              any failure to pay applicable charges may result in you not having
              access to some or all of the Site.
            </li>
            <li>
              <b>Linking.</b> We permit you to link to the Site provided that:
              (i) you link to (but do not replicate) any page on this Site; (ii)
              the hyperlink text shall accurately describe the Content as it
              appears on the Site; (iii) you shall not misrepresent your
              relationship with Hear Our Voices – Resilience Under Fire or
              present any false information about us, and shall not imply in any
              way that we are endorsing you or any services or products, unless
              we have given you our express prior consent to do so; (iv) you
              shall not link from a website which prohibits linking to third
              parties; (v) the website from which you link to the Site does not
              contain content that (a) is offensive or controversial (both at
              our discretion), or (b) infringes any Intellectual Property
              Rights; and/or (vi) you, and your website, comply with this
              Agreement and applicable law.
            </li>
            <li>
              <b>Intellectual Property Rights. </b>
              <br />
              <u>Content and Marks.</u> The (i) content and information on the
              Site, including without limitation, the text, documents, articles,
              brochures, descriptions, products, software, graphics, photos,
              sounds, videos, links, interactive features, and services
              (collectively, the <b>“Materials”</b> or <b>“Content”</b>), (and
              (ii) the trademarks, service marks, trade names, service names,
              trade dress, symbols, brands, and logos contained therein (
              <b>“Marks”</b>), are the property of Hear Our Voices – Resilience
              Under Fire and/or its licensors and may be protected by
              Intellectual Property Rights laws and treaties.{" "}
              <b>“Hear Our Voices – Resilience Under Fire”</b>, the Hear Our
              Voices – Resilience Under Fire logo, other marks are Marks of Hear
              Our Voices – Resilience Under Fire or its affiliates. All other
              Marks used or appearing on the Site are the Marks of their
              respective owners. We reserve all rights not expressly granted in
              and to the Site. <br />
              <u>Use of Content.</u> Content on the Site is provided to you for
              your information and personal use only and may not be used,
              modified, copied, distributed, transmitted, broadcast, displayed,
              sold, licensed, de-compiled, or otherwise exploited for any other
              purposes whatsoever without our prior written consent. If you
              download or print a copy of the Content, you must retain all
              copyright and other proprietary notices contained therein. In any
              event you wish to use, publish, copy, distribute, transmit,
              broadcast, display or otherwise exploit such Content, please be in
              touch with us at hearourvoicesIL@gmail.com in order to receive our
              written consent. <br />
            </li>
            <li>
              <b>Content Accuracy.</b> We attempt to be as accurate as possible.
              However, we cannot and do not warrant that the Content available
              on the Site is accurate, complete, reliable, current, or
              error-free. We reserve the right to make changes in or to the
              Content, or any part thereof, in our sole judgment, without the
              requirement of giving any notice prior to or after making such
              changes to the Content. Your use of the Content, or any part
              thereof, is made solely at your own risk and responsibility.
            </li>
            <li>
              <b>Third Party Content.</b> The Site may present, or otherwise
              allow you to view, access, link to, and/or interact with, Content
              from third parties and other sources that are not owned or
              controlled by us (such Content, <b>“Third Party Content”</b>). The
              Site may also enable you to communicate with the related third
              parties. The display or communication to you of such Third Party
              Content does not (and shall not be construed to) in any way imply,
              suggest, or constitute any sponsorship, endorsement, or approval
              by us of such Third Party Content or third party, or by such third
              party of us, and nor any affiliation between us and such third
              party. We do not assume any responsibility or liability for Third
              Party Content, or any third party’s terms of use, privacy
              policies, actions, omissions, or practices. Please read the terms
              of use and privacy policy of any third party that you interact
              with before you engage in any such activity.{" "}
            </li>
            <li>
              <b>Privacy.</b> We will use any personal information that we may
              collect or obtain in connection with the Site in accordance with
              our privacy policy which is available at:
              https://www.hearourvoices.io/privacy. You agree that we may use
              personal information that you provide or make available to us in
              accordance with the Privacy Policy.
            </li>
            <li>
              <b>Copyright Policy.</b> It is our policy to respect the
              legitimate rights of copyright and other intellectual property
              owners, and we will respond to clear notices of alleged copyright
              infringement.
            </li>
            <li>
              <b>Warranty Disclaimers. </b>
              <br />
              THE SITE (WHICH, FOR CLARITY, INCLUDES WITHOUT LIMITATION CONTENT
              AND MARKS) IS PROVIDED AND MADE AVAILABLE TO YOU ON AN “AS IS” AND
              “AS AVAILABLE” BASIS, WITH ALL FAULTS, AND WITHOUT ANY
              REPRESENTATION, WARRANTY, GUARANTEE OR CONDITION OF ANY KIND
              WHATSOEVER, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING
              WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, QUIET
              POSSESSION, TITLE, QUALITY OF SERVICE, NON-INFRINGEMENT, OR THAT
              OTHERWISE ARISE FROM A COURSE OF PERFORMANCE OR DEALING, OR USAGE
              OF TRADE, ALL OF WHICH ARE HEREBY DISCLAIMED BY US AND OUR
              LICENSORS AND SUPPLIERS. YOU AGREE THAT WE WILL NOT BE HELD
              RESPONSIBLE OR LIABLE FOR ANY CONSEQUENCES TO YOU OR ANY THIRD
              PARTY THAT MAY RESULT FROM TECHNICAL PROBLEMS OF THE INTERNET,
              SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER
              SERVERS.
              <br />
              YOU UNDERSTAND AND ACKNOWLEDGE THAT WHEN ACCESSING AND USING THE
              SITE: (I) YOU MAY BE EXPOSED TO OTHER USERS’ BEHAVIOR, AND THAT
              HEAR OUR VOICES – RESILIENCE UNDER FIRE IS NOT RESPONSIBLE FOR THE
              ACCURACY, USEFULNESS, SAFETY, OR INTELLECTUAL PROPERTY RIGHTS OF,
              OR RELATING TO, SUCH USER; AND (II) YOU MAY BE EXPOSED TO USERS’
              CONDUCT THAT IS INACCURATE, OFFENSIVE, INDECENT, OR OBJECTIONABLE.
              (III) YOU ACKNOWLEDGE AND AGREE THAT ANY USER CONTENT, INCLUDING
              WITHOUT LIMITATION TEXT CHATS AND VIDEO CHATS, IS NOT CREATED,
              ENDORSED, OR CONTROLLED BY HEAR OUR VOICES – RESILIENCE UNDER
              FIRE. (IV) AVAILABILITY OF THE SERVICES PROVIDED BY HEAR OUR
              VOICES – RESILIENCE UNDER FIRE MAY VARY AND IS DEPENDENT ON
              DIFFERENT FACTORS INCLUDING HEAR OUR VOICES – RESILIENCE UNDER
              FIRE’S DISCRETION. YOU HEREBY AGREE TO WAIVE, AND HEREBY DO WAIVE,
              ANY LEGAL OR EQUITABLE RIGHTS OR REMEDIES YOU MAY HAVE AGAINST
              HEAR OUR VOICES – RESILIENCE UNDER FIRE WITH RESPECT TO (I), (II),
              (III) AND (IV)) HEREIN.
              <br />
              IN ADDITION, NEITHER HEAR OUR VOICES – RESILIENCE UNDER FIRE NOR
              ITS LICENSORS OR SUPPLIERS MAKE ANY REPRESENTATION, WARRANTY,
              GUARANTEE OR CONDITION: (A) REGARDING THE EFFECTIVENESS,
              USEFULNESS, RELIABILITY, AVAILABILITY, TIMELINESS, ACCURACY, OR
              COMPLETENESS OF THE SITE; (B) THAT YOUR USE OF, OR RELIANCE UPON,
              THE SITE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; OR (C) THAT
              THE SITE WILL BE UNINTERRUPTED, SECURE, ERROR-FREE OR VIRUS-FREE,
              OR THAT DEFECTS IN THE SITE WILL BE CORRECTED.
              <br />
              YOU SPECIFICALLY ACKNOWLEDGE THAT HEAR OUR VOICES – RESILIENCE
              UNDER FIRE SHALL NOT BE RESPONSIBLE FOR THE CONDUCT (INCLUDING
              DEFAMATORY, OFFENSIVE, ILLEGAL, OR NEGLIGENT CONDUCT) OF ANY SITE
              USER AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS
              ENTIRELY WITH YOU. <br />
              YOUR RELIANCE ON, OR USE OF, ANY INTERACTION WITH ANY SITE USER OR
              OWNER, IS AT YOUR SOLE RISK. IF YOU HAVE A DISPUTE WITH ANY SITE
              USER OR OWNER IN CONNECTION WITH THE SITE, YOU AGREE THAT HEAR OUR
              VOICES – RESILIENCE UNDER FIRE IS NOT LIABLE FOR ANY CLAIMS OR
              DAMAGES ARISING OUT OF OR CONNECTED WITH SUCH A DISPUTE.
              <br />
              Applicable law may not allow the exclusion of certain warranties,
              so to that extent certain exclusions set forth herein may not
              apply.{" "}
            </li>
            <li>
              <b>Limitation of Liability. </b>
              <ol>
                <li>
                  IN NO EVENT SHALL HEAR OUR VOICES – RESILIENCE UNDER FIRE OR
                  ANY OF OUR LICENSORS OR SUPPLIERS BE LIABLE UNDER, OR
                  OTHERWISE IN CONNECTION WITH, THIS AGREEMENT FOR:{" "}
                  <ol>
                    <li>
                      ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR
                      PUNITIVE DAMAGES;
                    </li>
                    <li>
                      ANY LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF REVENUE, OR
                      LOSS OF ANTICIPATED SAVINGS;
                    </li>
                    <li>
                      ANY LOSS OF, OR DAMAGE TO, DATA, REPUTATION, OR GOODWILL;
                      AND/OR
                    </li>
                    <li>
                      THE COST OF PROCURING ANY SUBSTITUTE GOODS OR SERVICES.
                    </li>
                  </ol>
                </li>
                <li>
                  THE AGGREGATE LIABILITY OF HEAR OUR VOICES – RESILIENCE UNDER
                  FIRE UNDER, OR OTHERWISE IN CONNECTION WITH, THIS AGREEMENT
                  SHALL NOT EXCEED THE LOWER OF: (A) FIVE U.S. DOLLARS (US $5),
                  AND (B) THE AMOUNTS ACTUALLY PAID BY YOU (IF ANY) TO US DURING
                  THE THREE (3) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH
                  YOU BRING YOUR CLAIM.
                </li>
                <li>
                  THE FOREGOING EXCLUSIONS AND LIMITATIONS SHALL APPLY: (A) TO
                  THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW; (B) EVEN IF
                  HEAR OUR VOICES – RESILIENCE UNDER FIRE HAS BEEN ADVISED, OR
                  SHOULD HAVE BEEN AWARE, OF THE POSSIBILITY OF LOSSES, DAMAGES,
                  OR COSTS; (C) EVEN IF ANY REMEDY IN THIS AGREEMENT FAILS OF
                  ITS ESSENTIAL PURPOSE; AND (D) REGARDLESS OF THE THEORY OR
                  BASIS OF LIABILITY, INCLUDING WITHOUT LIMITATION NEGLIGENCE,
                  MISREPRESENTATION, STRICT LIABILITY, OR OTHER CONTRACT OR TORT
                  LIABILITY.
                </li>
                <li>
                  Some jurisdictions do not allow the exclusion or limitation of
                  incidental or consequential damages, or of other damages, and
                  to the extent applicable to you, such exclusions and
                  limitations shall not apply. Furthermore, nothing in this
                  Agreement shall be deemed to exclude or limit liability for
                  death or personal injury resulting from negligence, or for
                  fraud or fraudulent misrepresentation.{" "}
                </li>
              </ol>
            </li>
            <li>
              <b>Indemnity.</b> You agree to defend, indemnify and hold harmless
              Hear Our Voices – Resilience Under Fire and our affiliates, and
              our respective officers, directors, employees and agents, from and
              against any and all claims, damages, obligations, losses,
              liabilities, costs and expenses (including but not limited to
              attorney’s fees) arising from: (i) your use of, or inability to
              use, the Site; or (ii) breach of any provision of this Agreement.
              <li>
                <b>Term and Termination.</b>
                <ol>
                  <li>
                    This Agreement commences on the Effective Date and shall
                    continue in full force and effect until terminated in
                    accordance herewith (the <b>“Term”</b>).
                  </li>
                  <li>
                    We reserve the right to immediately terminate this
                    Agreement, or otherwise modify, ban, suspend, or discontinue
                    your access to and use of the Site (or any part thereof),
                    for any reason whatsoever, at any time, and without notice
                    or obligation to you, and you agree that Hear Our Voices –
                    Resilience Under Fire shall have no liability to you or any
                    third party for any such termination, modification,
                    suspension, or discontinuance.
                  </li>
                  <li>
                    You may terminate this Agreement at any time and for any
                    reason. If you object to any term or condition of this
                    Agreement or any subsequent changes thereto, or become
                    dissatisfied with the Site in any way, your sole remedy is
                    to terminate this Agreement and to immediately discontinue
                    use of the Site.
                  </li>
                </ol>
              </li>
            </li>
            <li>
              <b>Consequences of Termination and Survival.</b> Upon termination
              of this Agreement your right to access and use the Site will
              automatically terminate and be deemed revoked. Sections 8
              (Intellectual Property Rights) through 23 (Entire Agreement)
              inclusive shall survive any termination of this Agreement.
              Termination shall not affect any rights and obligations accrued as
              of the effective date of termination.
            </li>
            <li>
              <b>Independent Contractors.</b> You and Hear Our Voices –
              Resilience Under Fire are independent contractors. Nothing in
              these Terms creates a partnership, joint venture, agency, or
              employment relationship between you and Hear Our Voices –
              Resilience Under Fire. You must not under any circumstances make,
              or undertake, any warranties, representations, commitments, or
              obligations on behalf of Hear Our Voices – Resilience Under Fire.
            </li>
            <li>
              <b>Assignment.</b> Hear Our Voices – Resilience Under Fire may
              assign this Agreement (or any of its rights and/or obligations
              hereunder) without your consent, and without notice or obligation
              to you. This Agreement is personal to you, and you shall not
              assign (or in any other way transfer) this Agreement (or any of
              your obligations or rights hereunder) without Hear Our Voices –
              Resilience Under Fire’s express prior written consent. Any
              prohibited assignment shall be null and void.
            </li>
            <li>
              <b>Governing Law and Place of Jurisdiction.</b> This Agreement
              (including without limitation its validity and formation) shall be
              governed by, and construed in accordance with, the laws of the
              State of Israel, without regard to any conflicts of laws rules or
              principles. The United Nations Convention on Contracts for the
              International Sale of Goods and the Uniform Computer Information
              Transaction Act shall not apply to this Agreement and are hereby
              disclaimed. The competent courts of the city of Tel Aviv-Yafo
              shall have the exclusive jurisdiction with respect to any dispute
              and action arising under or in relation to this Agreement.
            </li>
            <li>
              <b>Severability.</b> If any provision of this Agreement is held by
              a court of competent jurisdiction to be invalid, illegal, or
              unenforceable, then: (a) the remaining provisions of this
              Agreement shall remain in full force and effect; and (b) such
              affected provision shall be ineffective solely as to such
              jurisdiction (and only to the extent and for the duration of such
              invalidity, illegality, or unenforceability), and shall be
              substituted (in respect of such jurisdiction) with a valid, legal,
              and enforceable provision that most closely approximates the
              original legal intent and economic impact of such provision.
            </li>
            <li>
              <b>Remedies.</b> Except as may be expressly stated otherwise in
              this Agreement, no right or remedy conferred upon or reserved by
              any party under this Agreement is intended to be, or shall be
              deemed, exclusive of any other right or remedy under this
              Agreement, at law or in equity, but shall be cumulative of such
              other rights and remedies.
            </li>
            <li>
              <b>Waiver.</b> No failure or delay on the part of any party in
              exercising any right or remedy under this Agreement shall operate
              as a waiver thereof, nor shall any single or partial exercise of
              any such right or remedy preclude any other or further exercise
              thereof or the exercise of any other right or remedy. Any waiver
              granted hereunder must be in writing (for waivers by you, emails
              will be acceptable; for waivers by us, the writing must be duly
              signed by an authorized representative of Hear Our Voices –
              Resilience Under Fire) and shall be valid only in the specific
              instance in which given.
            </li>
            <li>
              <b>Entire Agreement.</b> This Agreement represents the entire
              agreement between Hear Our Voices – Resilience Under Fire and you
              with respect to the subject matter hereof and supersedes and
              replaces any and all prior and contemporaneous oral and/or written
              agreements, understandings and statements between you and us with
              respect to such subject matter. You acknowledge and agree that in
              entering into this Agreement you have not relied on any statement
              or representation (whether negligently or innocently made) not
              expressly set out in this Agreement; for example, statements and
              explanations in any FAQs or other marketing material on the Site
              (defined below) are for convenience only and are not binding or a
              part of this Agreement.
            </li>
          </ol>
          <b>Last updated: October 25, 2023</b>
        </section>
      </Container>
    </ThemeProvider>
  );
}
export default function TermsPage() {
  return <TermsContent />;
}
