import * as React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import mdTheme from '../../../theme'
import './LandingPage.css'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'

export default function LandingPage() {
  const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    marginBottom: '20px',
  }

  const listItems = {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  }

  const itemData = [
    {
      img: '/images/sunvideo.svg',
      title: 'Sun Video',
    },
    {
      img: '/images/tlvmua.svg',
      title: 'Tel Aviv Musuem of Arts',
    },
    {
      img: '/images/isrvocal.svg',
      title: 'Israel Vocal Arts Institute',
    },
    {
      img: '/images/folks.svg',
      title: 'FolksPat',
    },
    {
      img: '/images/OpusHD.png',
      title: 'OpusMedia',
    },
    {
      img: '/images/act.svg',
      title: 'Act',
    },
    {
      img: '/images/meitar.svg',
      title: 'Meitar',
    },
  ]

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            // height: "100vh",
            overflow: 'auto',
          }}
        >
          <Container maxWidth='md' sx={{ mt: 4, mb: 4 }}>
            <Box
              component='img'
              sx={{
                margin: '0 auto',
                display: 'flex',
                padding: '5%',
                height: '100%',
                maxWidth: '600px',
              }}
              alt='HearOurVoices concert - Save the Date!'
              src='/images/savethedate-logo.svg'
            />
            <List sx={flexContainer}>
              <ListItem sx={listItems}>
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 700,
                    fontSize: '1.25em',
                  }}
                  primary='TEL AVIV'
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 500,
                    fontSize: '1.1em',
                  }}
                  primary='8PM'
                />
              </ListItem>
              <Divider color='#cc9764' sx={{ width: 2, height: '92px' }} />
              <ListItem sx={listItems}>
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 700,
                    fontSize: '1.25em',
                  }}
                  primary='NEW YORK'
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 500,
                    fontSize: '1.1em',
                  }}
                  primary='2PM'
                />
              </ListItem>
              <Divider color='#cc9764' sx={{ width: 2, height: '92px' }} />
              <ListItem sx={listItems}>
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 700,
                    fontSize: '1.25em',
                  }}
                  primary='LOS ANGELES'
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: 500,
                    fontSize: '1.1em',
                  }}
                  primary='11AM'
                />
              </ListItem>
            </List>
            <Typography
              sx={{
                bgcolor: 'hsl(28.57deg 48.84% 83.14%)',
                textAlign: 'center',
                lineHeight: 1.5,
                fontWeight: 600,
                paddingY: 1.5,
                letterSpacing: 1.5,
                fontSize: '1.2em',
              }}
            >
              AN INTERNATIONAL MUSICAL FUNDRAISER FOR THE PEOPLE OF ISRAEL
            </Typography>
            <ImageList sx={{ marginTop: 5 }} cols={7} rowHeight={164}>
              {itemData.map((item) => (
                <ImageListItem
                  style={{ height: 50, maxWidth: 100, margin: 5 }}
                  key={item.img}
                >
                  <img
                    style={{
                      maxHeight: 50,
                      maxWidth: 100,
                      objectFit: 'contain',
                    }}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    alt={item.title}
                    loading='lazy'
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <Typography
              sx={{
                textAlign: 'center',
                lineHeight: 1.5,
                fontWeight: 600,
                marginTop: 5,
                marginBottom: 1,
                letterSpacing: 1.5,
                fontSize: '1.25em',
              }}
            >
              Contact us
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                lineHeight: 1.5,
                fontWeight: 500,
                paddingBottom: 1.5,
                letterSpacing: 1.5,
                fontSize: '1.2em',
              }}
            >
              <a style={{ color: '#c79265' }} href='mailto:tdrissman@gmail.com'>
                tdrissman@gmail.com
              </a>{' '}
              | +972-(0)54-686-9257 / +972-(0)52-342-4511
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
