import * as React from 'react'
import './Gallery.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import mdTheme from '../../theme'

function GalleryContent() {
  const artists = [
    { name: 'miri', fullname: 'miri michaeli' },
    { name: 'corinne', fullname: 'corinne allal' },
    { name: 'arkadi', fullname: 'arkadi duchin' },
    { name: 'assaf', fullname: 'assaf amdursky' },
    { name: 'shira', fullname: 'shira gabrielov' },
    { name: 'omri', fullname: 'omri glikman' },
    { name: 'inon', fullname: 'inon zur' },
    { name: 'eliana', fullname: 'eliana tidhar' },
    { name: 'lee', fullname: 'lee biran' },
    { name: 'miki', fullname: 'miki gavrielov' },
    { name: 'garry', fullname: 'garry schyman' },
    { name: 'maya', fullname: 'maya rose' },
    { name: 'ariella', fullname: 'ariella zeitlin' },
    { name: 'nami', fullname: 'nami melumad' },
    { name: 'noreen', fullname: 'dr. noreen green' },
    { name: 'sharon', fullname: 'sharon farber' },
  ]

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <section className='gallery-section'>
          <h3>The Artists</h3>
          {/* <p>I am a subtitle</p> */}
          <div>
            <div className='gallery-container'>
              {artists.map((artist, key) => (
                <div key={key} className='card'>
                  <div
                    role='image'
                    arial-label={artist.fullname}
                    className='card-img'
                    style={{
                      backgroundImage: `url('/images/artists/${artist.name}.jpg')`,
                    }}
                  ></div>
                  {/* <div className='card-text'>
                    <p>{artist.name}</p>
                    <p>{artist.subtitle}</p>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </section>
      </Container>
    </ThemeProvider>
  )
}

export default function Gallery() {
  return <GalleryContent />
}
