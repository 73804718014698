import * as React from "react";
import "./NotFound.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import mdTheme from "../../../theme";
import { Box } from "@mui/system";

export default function NotFound() {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <h2>404</h2>
      <h1>Page Not Found</h1>\{" "}
    </Box>
  );
}
