import * as React from 'react'
import classes from './LiveStream.module.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import mdTheme from '../../theme'

function StreamContent() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth='lg'>
        <section className={classes.liveStream}>
          <div className={classes.streamContainer}>
            <iframe
              src='https://www.youtube.com/embed/UYyveBzKAKE?si=OzHPf1AYWBLMpDmF'
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share full'
              allowFullScreen
            ></iframe>
          </div>
        </section>
      </Container>
    </ThemeProvider>
  )
}

export default function LiveStream() {
  return <StreamContent />
}
