import * as React from 'react'
import classes from './Who.module.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import mdTheme from '../../../theme'
import { Grid, Typography, styled } from '@mui/material'
import Paper from '@mui/material/Paper'
import Banner from '../../banner/Banner'

export default function Who() {
  const whoList = [
    {
      imgName: 'Talya',
      name: 'Talya Woolf',
      title: 'Co-Producer & Head of Content',
    },
    { imgName: 'Tom', name: 'Tom Tsaraf', title: 'Director' },
    { imgName: 'Dahlia', name: 'Dahlia Goldstein', title: 'Producer' },
    {
      imgName: 'Neta',
      name: 'Neta Kirschenbaum',
      title: 'Head of Web & Marketing Design',
    },
    { imgName: 'Or', name: 'Or Rosenstein', title: 'Head of Graphic Design' },

    { imgName: 'Erez', name: 'Erez Rotem', title: 'Video Content' },
  ]

  const whoListTech = [
    { imgName: 'Jeremy', name: 'Jeremy Tisser', title: 'LA chief producer' },
    { imgName: 'Gali', name: 'Gali Snunit', title: 'AP & research' },
    { imgName: 'Assaf', name: 'Assaf Kreif', title: 'Technical Producer' },
    { imgName: 'Gil', name: 'Gil Mor', title: 'Head of Development' },
    { imgName: 'Roni', name: 'Roni Siles', title: 'Developer' },
  ]

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: 25,
    paddingTop: '20px !important',
    paddingBottom: '20px !important',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  }))

  const titleStyle = {
    color: '#000',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '125%',
    letterSpacing: '-0.48px',
  }

  const subTitleStyle = {
    color: '#656565',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '125%',
    letterSpacing: '-0.32px',
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth='lg'>
        <section className={`${classes.whoPage}`}>
          <h2>Who we are</h2>
          <div>
            <p>
              Our passionate group of dedicated producers and designers has
              harnessed the power of commitment to fuel our unwavering
              dedication to the cause. An all-volunteer team, we are driven by a
              ferocious love for Israel and humanity, determined to make a
              meaningful impact.
              <br />
              With meticulous planning, attention to detail, and a tireless work
              ethic, we have forged a well-rounded and efficient team. Each
              member brings their unique talents and expertise to the table, and
              our synergy allows us to work seamlessly together.
              <br />
              We are ready to make our upcoming event a resounding success,
              driven by a common purpose and a strong, unbreakable bond of
              support for Israel – our family – in the face of adversity.
            </p>
          </div>
        </section>
      </Container>

      <Grid sx={{ backgroundColor: 'white' }}>
        <Grid
          sx={{
            backgroundColor: 'white',
            margin: '0 auto',
            maxWidth: '1080px',
            justifyContent: 'center',
          }}
          container
          spacing={2}
        >
          <Grid item md={12} xs={12} className={`${classes.personCard}`}>
            <Item>
              <div className={classes.personName}>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 600, textAlign: 'center' }}
                >
                  FOUNDING TEAM
                </Typography>
              </div>
            </Item>
          </Grid>
          {whoList.map((img, key) => (
            <Grid
              item
              md={4}
              xs={12}
              className={`${classes.personCard}`}
              key={key}
            >
              <Item>
                <img
                  className={`${classes.personCardImg}`}
                  src={`/who/${img.imgName}.png`}
                ></img>
                <div className={classes.personName}>
                  <Typography variant='h5' sx={titleStyle}>
                    {img.name}
                  </Typography>
                  <Typography sx={subTitleStyle} variant='subtitle1'>
                    {img.title}
                  </Typography>
                </div>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid sx={{ backgroundColor: 'white', pt: 3, pb: 5 }}>
        <Grid
          sx={{
            backgroundColor: 'white',
            margin: '0 auto',
            maxWidth: '1080px',
            justifyContent: 'center',
          }}
          container
          spacing={2}
        >
          <Grid item md={12} xs={12} className={`${classes.personCard}`}>
            <Item>
              <div className={classes.personName}>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 600, textAlign: 'center' }}
                >
                  TECHNICAL TEAM
                </Typography>
              </div>
            </Item>
          </Grid>
          {whoListTech.map((img, key) => (
            <Grid
              item
              md={4}
              xs={12}
              className={`${classes.personCard}`}
              key={key}
            >
              <Item>
                <img
                  className={`${classes.personCardImg}`}
                  src={`/who/${img.imgName}.png`}
                ></img>
                <div className={classes.personName}>
                  <Typography variant='h5' sx={titleStyle}>
                    {img.name}
                  </Typography>
                  <Typography sx={subTitleStyle} variant='subtitle1'>
                    {img.title}
                  </Typography>
                </div>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Banner></Banner>
    </ThemeProvider>
  )
}
