import * as React from 'react'
import classes from './HomePage.module.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import mdTheme from '../../../theme'
import Banner from '../../banner/Banner'
import LogoBanner from '../../logobanner/LogoBanner'
import { Button } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import Gallery from '../../gallery/Gallery'
// import LiveStream from '../../livestream/LiveStream'

function HomeContent() {
  const times = [
    { city: 'Tel Aviv', time: '8PM' },
    { city: 'Los Angeles', time: '11AM' },
  ]

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth='lg'>
        <section className={`${classes.section1} flex`}>
          <div className={`${classes.textContainer} `}>
            <h1 className={`${classes.textTitle} `}>Restoring Hope</h1>
            <h2>Rebuilding Israel Together</h2>
            <p>
              We aim to raise money and find donors who will provide houses,
              furnishings, necessities, and more – whatever is necessary – to
              help the affected families start living their “new normal.”
            </p>
            <div className={`${classes.btns} flex`}>
              <button className={`${classes.appBtn} ${classes.brown} `}>
                <a href='./donate'> Donate</a>
              </button>
              <button className={`${classes.appBtn} `}>
                <a href='./contact'> Become a Sponsor</a>
              </button>
            </div>
            <br></br>
            <p className={classes.remark}>
              *All profits will go to the families that have lost their homes
              and loved ones.{' '}
            </p>
            <h3>October 29, 2023</h3>
            <div className={`${classes.time} `}>
              {times.map((time, key) => (
                <div key={key} className={`${classes.timeContainer}`}>
                  <p className={`${classes.city} `}>
                    <b>{time.city}</b>
                  </p>
                  <p>{time.time}</p>
                </div>
              ))}
            </div>
            <Container sx={{ my: 2, display: 'flex', gap: 2 }}>
              <Button
                id='addGoogle'
                href='https://www.google.com/calendar/render?action=TEMPLATE&text=Hear%20Our%20Voices%20-%20Resilience%20Under%20Fire%20%7C%20An%20International%20Fundraising%20Event&dates=20231029T190000Z/20231029T210000Z&details=Hear%20Our%20Voices%20-%20Resilience%20Under%20Fire%20%7C%20An%20International%20Fundraising%20Event%0D%0ADate%20and%20Time%3A%20Oct%2029%2C%202023%207%3A00%20PM%20-%209%3A00%20PM%0D%0AVenue%3A%20https%3A%2F%2Fhearourvoices.io%2F%0D%0AThe%20live%20show%20will%20be%20broadcast%20on%20https%3A%2F%2Fhearourvoices.io%2F&location=https%3A%2F%2Fhearourvoices.io%2F&trp=true&sf=true&output=xml#f'
                className={`${classes.appBtn} ${classes.google} flex`}
                sx={{
                  backgroundColor: '#5f90ec !imporant',
                  border: '1px solid #5f90ec',
                  color: 'white',
                  display: 'block',
                  textAlign: 'center',
                  fontWeight: '500',
                  '&:hover': {
                    border: '1px solid #5f90ec',
                    backgroundColor: 'white',
                    color: '#99714B',
                  },
                }}
              >
                <EventIcon /> Add to Google Calendar
              </Button>
              <Button
                href='./20231029-hear-our-voices-resilience-under-fire-an-international-fundraising-event.ics'
                className={`${classes.appBtn} ${classes.google} ${classes.otherCalendar} flex`}
                sx={{
                  backgroundColor: '#5f90ec !imporant',
                  border: '1px solid #5f90ec',
                  color: 'white',
                  display: 'block',
                  textAlign: 'center',
                  fontWeight: '500',
                  '&:hover': {
                    border: '1px solid #5f90ec',
                    backgroundColor: 'white',
                    color: '#99714B',
                  },
                }}
              >
                <EventIcon /> Other Calendars
              </Button>
            </Container>
          </div>
          <div className={`${classes.imageContainer} `}>
            <img src='./images/hero.png' alt='' />
          </div>
        </section>
      </Container>
      <LogoBanner></LogoBanner>
      <Container maxWidth='sm'>
        <section className={`${classes.ourMission}`}>
          <h2>Our Mission</h2>
          <p>Standing by our family</p>
          <div>
            <p>
              Our mission is to help rebuild the lives of Israeli citizens
              directly affected by the murderous Hamas rampage that stole 1400
              innocent lives. We aim to provide them with support and money that
              allows them to reconstruct their lives.
            </p>
          </div>
          <button className={`${classes.appBtn} `}>
            <a href='./mission'>Learn More</a>
          </button>
        </section>
      </Container>
      <Gallery></Gallery>

      {/* <Gallery></Gallery> */}
      <Banner></Banner>
    </ThemeProvider>
  )
}

export default function HomePage() {
  return <HomeContent />
}
