import * as React from 'react'
import classes from './Banner.module.css'
import { Button } from '@mui/material'

function BannerContent() {
  return (
    <section className={`${classes.banner} flex`}>
      <h3>Click here to donate</h3>
      <h4>Support Israeli families</h4>
      <div className={`${classes.btns}`}>
        <Button
          href='/donate'
          className={`${classes.appBtn} ${classes.brown} flex`}
          sx={{
            xs: { width: "100%" },
            backgroundColor: '#99714B',
            border: '1px solid #99714B',
            color: 'white',
            display: 'flex',
            letterSpacing: "-0.24px",
            textTransform: "none",
            padding: "16px 24px",
            '&:hover': {
              border: '1px solid #99714B',
              backgroundColor: 'white',
              color: '#99714B',
            },
          }}
        >
          Donate
        </Button>
        <Button
          href='/contact'
          sx={{
            display: 'block',
            padding: "16px 24px",
            border: '1px solid black',
            letterSpacing: "-0.24px",
            textTransform: "none",
            color: 'black',
            '&:hover': {
              border: '1px solid #99714B',
              color: 'white',
              backgroundColor: '#99714B',
            },
          }}
          className={`${classes.appBtn}`}
        >
          Become a Sponsor
        </Button>
      </div>
    </section>
  )
}

export default function Banner() {
  return <BannerContent />
}
