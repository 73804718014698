import * as React from 'react'
import classes from './LogoBanner.module.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import mdTheme from '../../theme'

function LogoBannerContent() {
  const logos = [
    'opera',
    'opus',
    'sinai',
    'tlvmuseum',
    'sunvideo',
    'matan',
    'meitar',
    'folkspat',
    'israelcenter',
    'symphony',
    'meitar2',
    'act',
  ]
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <div className={classes.mainContainer}>
          <div className={classes.BannerContainer}>
            <section className={`${classes.logoBanner} `}>
              {logos.map((logos, key) => (
                <div key={key} className={`${classes.logoContainer} `}>
                  <img src={`/images/logos/${logos}.svg`}></img>
                </div>
              ))}
            </section>
            <section className={`${classes.logoBanner} `}>
              {logos.map((logos, key) => (
                <div key={key} className={`${classes.logoContainer} `}>
                  <img src={`/images/logos/${logos}.svg`}></img>
                </div>
              ))}
            </section>
          </div>
        </div>
      </Container>
    </ThemeProvider>
  )
}
export default function LogoBanner() {
  return <LogoBannerContent />
}
