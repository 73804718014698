import * as React from 'react'
import classes from './About.module.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import mdTheme from '../../../theme'
import Carousel from '../../carousel/Carousel'
import Banner from '../../banner/Banner'
import LogoBanner from '../../logobanner/LogoBanner'
import MovieClip from '../../../components/movieclip/MovieClip'

function AboutContent() {
  const times = [
    { city: 'Tel Aviv', time: '8PM' },
    { city: 'Los Angeles', time: '11AM' },
  ]

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth='xl'>
        <section className={`${classes.section1}`}>
          <div className={`${classes.textContainer}`}>
            <h1 className={`${classes.textTitle}`}>About the event</h1>
            <p>
              On October 29, 2023 at 8:00pm (Israel time), An inspiring,
              uplifting, and touching musical experience with talented musicians
              and singers from around the globe will take place at the notable
              Tel Aviv Museum of Art.
            </p>
            <p>
              Advanced technology will allow musical talent from Los Angeles to
              join Israeli singers simultaneously, merging voices and
              determination.
            </p>
            {/* <p>
              <b>
                **Link to the show will be available 30 minutes before
                the show starts.
              </b>
            </p> */}
            {/* <button className={classes.appBtn}>
              <a href='./watch'>Watch the live show</a>
            </button> */}
            {/* <button className={`${classes.appBtn}`}>
              <a>
                <svg
                  width='15'
                  height='17'
                  viewBox='0 0 15 17'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M14.016 5.99852H10.016V-0.00848389H4V5.99852H0L7.008 13.0065L14.016 5.99852ZM0 15.0085V17.0085H14.016V15.0085H0Z' />
                </svg>
                Program
              </a>
            </button> */}
          </div>
          <div className={`${classes.eventTime}`}>
            <div className={classes.imgContainer}>
              <img src='./images/hero.png' alt='' />
            </div>
            <h3>October 29, 2023</h3>
            <div className={`${classes.time}`}>
              {times.map((time, key) => (
                <div key={key}>
                  <p>
                    <b>{time.city}</b>
                  </p>
                  <p>{time.time}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Container>
      <LogoBanner></LogoBanner>
      <Banner></Banner>
    </ThemeProvider>
  )
}

export default function About() {
  return <AboutContent />
}
