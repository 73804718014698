import React from 'react'
import { styled, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import mdTheme from '../../theme'
import MainLogo from '../../assets/images/main-logo.svg'
import WhiteLogo from '../../assets/images/hearourvoices-logo.svg'
import Menu from '@mui/material/Menu'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import classes from './ResponsiveNavbar.module.css'

function Copyright(props: any) {
  return (
    <Typography
      variant='div'
      color='text.secondary'
      align='center'
      display='flex'
      {...props}
    ></Typography>
  )
}

const drawerWidth: number = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

interface ResponsiveNavbarProps {
  children: React.ReactNode
}

export const ResponsiveNavbar: React.FC<ResponsiveNavbarProps> = ({
  children,
}: ResponsiveNavbarProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const links = [
    { url: '/mission', text: 'OUR MISSION' },
    { url: '/who', text: 'WHO WE ARE' },
    { url: '/about', text: 'ABOUT THE EVENT' },
    { url: '/contact', text: 'CONTACT US' },
  ]

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <AppBar
        dir='ltr'
        position='fixed'
        style={{ boxShadow: 'none', padding: 5, backgroundColor: 'white' }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingY: 0.5,
          }}
        >
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex' } }}>
            <a href='/'>
              <img src={MainLogo} alt='Logo' />
            </a>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {links.map((link, index) => (
                <Button
                  key={index}
                  href={link.url}
                  sx={{
                    my: 2,
                    mx: 1,
                    color: '#343434',
                    display: 'block',
                    paddingY: 1,
                    fontSize: '1em',
                  }}
                >
                  {link.text}
                </Button>
              ))}
              <Button
                href='/privacy'
                sx={{
                  my: 2,
                  mx: 1,
                  color: '#343434',
                  display: 'block',
                  paddingY: 1,
                  fontSize: '1em',
                }}
              >
                Privacy
              </Button>
              <Button
                href='/terms'
                sx={{
                  my: 2,
                  mx: 1,
                  color: '#343434',
                  display: 'block',
                  paddingY: 1,
                  fontSize: '1em',
                }}
              >
                Terms
              </Button>
              <Button
                // href='/watch'
                sx={{
                  my: 2,
                  mx: 2,
                  backgroundColor: '#99714B',
                  textAlign: 'start',
                  color: 'white',
                  display: 'block',
                  paddingY: 1,
                  border: '2px solid #99714B',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#99714B',
                  },
                }}
              >
                WATCH LIVE SHOW
              </Button>
            </Menu>
          </Box>
          <Box
            sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'start' }}
          >
            {links.map((link, index) => (
              <Button
                key={index}
                href={link.url}
                sx={{
                  my: 2,
                  mx: 1,
                  color: '#343434',
                  display: 'block',
                  paddingY: 1,
                  fontSize: '1em',
                  textAlign: 'center',
                }}
              >
                {link.text}
              </Button>
            ))}
            <Button
              // href='/watch'
              sx={{
                my: 2,
                mx: 2,
                backgroundColor: '#99714B',
                textAlign: 'center',
                color: 'white',
                display: 'block',
                paddingX: 6,
                paddingY: 1,
                border: '2px solid #99714B',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#99714B',
                },
              }}
            >
              WATCH LIVE SHOW
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Container maxWidth={false} disableGutters sx={{ overflowY: 'hidden' }}>
        {children}
        <Copyright
          sx={{
            backgroundColor: '#99714B',
            height: '96px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            color: 'white',
          }}
        >
          <small>
            {'Copyright © '}
            Hear Our Voices - Resilience Under Fire
            {' ' + new Date().getFullYear()}
          </small>
          <img className={classes.whiteLogo} src={WhiteLogo} alt='Logo' />
          <ul className={classes.footerNav}>
            <li>
              <a href='/privacy'>Privacy</a>
            </li>
            <li>
              <a href='/terms'>Terms</a>
            </li>
            <li>
              <a href='/contact'>Contact us</a>
            </li>
          </ul>
        </Copyright>
      </Container>
    </ThemeProvider>
  )
}

export default ResponsiveNavbar
