import * as React from "react";
import classes from "./Privacy.module.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import mdTheme from "../../../theme";

function PrivacyContent() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <section className={classes.privacyContainer}>
          <h1>Privacy Policy</h1>
          <p>
            In order to ensure transparency and give you more control over your
            personal information, this privacy policy (<b>“Privacy Policy”</b>) governs
            how we, Hear Our Voices – Resilience Under Fire (<b>“Hear Our Voices –
            Resilience Under Fire”</b>, <b>“we”</b>, <b>“our”</b> or <b>“us”</b>) use, collect, and store
            personal information that we collect or receive from or about you
            (<b>“you”</b>) in connection with the use of our website (the <b>"Site"</b>),
            <a style={{color: "#cc9764"}} href="https://hearourvoices.io">https://hearourvoices.io</a> (together with its sub-domains, content and
            services, the <b>“Services</b>").
            <br />
            We greatly respect your privacy, which is why we make every effort
            to provide a platform that would live up to the highest user privacy
            standards. Please read this Privacy Policy carefully so you can
            fully understand our practices concerning personal data. <br />
          </p>
          <ul>
            <li>
              <b>Introduction.</b> We have implemented this Privacy Policy because your
              privacy and other users' privacy are important to us. This Privacy
              Policy explains our online information practices and the choices
              you can make about how your Personal Information is collected and
              used in connection with the Services. <b>“Personal Information”</b> means
              any information that may be used, alone or in combination with
              other information, to personally identify an individual,
              including, but not limited to, a first and last name, an email
              address, and a phone number. Please read this Privacy Policy
              carefully so you can fully understand our practices concerning
              personal data.
            </li>
            <li>
              <b>Terms of Use.</b> This Privacy Policy forms part of our Terms of Use,
              which is available at hearourvoices.io/terms (<b>“Terms”</b>). Any
              capitalized but undefined term in this Privacy Policy shall have
              the meaning given to it in the Terms.
            </li>
            <li>
              <b>Consent and Modification.</b> You are not legally obligated to provide
              us with Personal Information, and you hereby confirm that
              providing us with Personal Information is of your own free will.
              By using the Services, you consent to the terms of this Privacy
              Policy and to our collection, processing, and sharing of Personal
              Information for the purposes set forth herein. If you do not agree
              to this Privacy Policy, please do not access or otherwise use the
              Services. We reserve the right, at our discretion, to change this
              Privacy Policy at any time. Such change will be immediately
              following the posting of the revised Privacy Policy on the Site,
              and your continued use of the Services thereafter means that you
              accept those changes.
            </li>
            <li>
              <b>What Personal Information We Collect and How We Collect It.</b> We do
              not currently require you to provide Personal Information in order
              to have access to general information available on the Site. But,
              we do receive and/or collect Personal Information from you in the
              following ways:
              <ul>
                <li>
                  <u>Information you provide to us</u>. We may collect
                  information you provide to us directly, for example, when you
                  submit your information on our contact page, submit feedback,
                  interact with Hear Our Voices – Resilience Under Fire support,
                  or use the Services. This includes contact information, such
                  as name, email address, and telephone number, and any other
                  information you choose to provide to us about yourself or
                  others.
                </li>
                <li>
                  <u>Log Files</u>. When you browse or visit our Site, we may
                  make use of log files. The information inside the log files
                  includes internet protocol (IP) addresses, type of browser,
                  amount of data transferred, Internet Service Provider (ISP),
                  date/time stamp, referring/exit pages, clicked pages, and any
                  other information your browser may send to us. We use the log
                  data only for statistical evaluations for the purpose of the
                  operation, security and optimization of the online offer.
                  However, we reserve the right to subsequently check the log
                  data if there is a justified suspicion of unlawful use due to
                  concrete indications.
                </li>
                <li>
                  <u>Cookies and Other Tracking Technologies</u>. When you
                  browse or visit our Site, our Services may utilize “cookies”,
                  anonymous identifiers, and other tracking technologies for us
                  to provide our Services and present you with information that
                  is customized for you. A “cookie” is a small text file that
                  may be used, for example, to collect information about
                  activity on the Services. Certain cookies and other
                  technologies may serve to recall Personal Information, such as
                  an IP address, previously indicated by a user. Most browsers
                  allow you to control cookies, including whether to accept them
                  and how to remove them. You may set most browsers to notify
                  you if you receive a cookie, or you may choose to block
                  cookies with your browser.
                </li>
              </ul>
            </li>
            <li>
              <b>The Way We Use Personal Information.</b> If you submit or we collect
              Personal Information through the Services, then we may use such
              Personal Information in the following ways:
            </li>
            <li>
              We will use your Personal Information to provide and improve our
              Services, and to contact you in connection with the Services and
              certain programs or offerings that you may have registered for.{" "}
            </li>
            <li>
              We may use your designated email address to send you updates or
              news regarding the Services.
            </li>
            <li>
              <b>The Way We Share Personal Information.</b> We do not share or disclose
              your Personal Information with third parties except in the
              following ways: We may share your Personal Information with our
              third party service providers and partners, but only to assist us
              with our business operations and to provide our Services to you
              and other users. Such information may be transferred to other
              countries. We use commercially reasonable efforts to only engage
              or interact with third party service providers and partners that
              post a privacy policy governing their processing of Personal
              Information.
              <br />
              We may disclose your Personal Information or any information you
              submitted via the Services if we have a good faith belief that
              disclosure of such information is helpful or reasonably necessary
              to: (i) comply with any applicable law, regulation, legal process
              or governmental request; (ii) enforce our Terms of Use, including
              investigations of potential violations thereof; (iii) detect,
              prevent, or otherwise address fraud or security issues; or (iv)
              protect against harm to the rights, property or safety of Company,
              our users, yourself or the public.
            </li>
            <li>
              <b>Use of Anonymous Information.</b> We may use Anonymous Information (as
              defined below) or disclose it to third party service providers to
              improve our Services and enhance your experience with the
              Services. We may also disclose Anonymous Information to third
              parties. <b>“Anonymous Information”</b> means information which does not
              enable identification of an individual user, such as aggregated
              information about the use of our Services.
            </li>
            <li>
              <b>Opting Out.</b> You may choose not to receive future promotional,
              advertising, or other Services-related emails from us by selecting
              an unsubscribe link at the bottom of each email that we send.
            </li>
            <li>
              <b>Choice.</b> At all times, you may choose whether to provide or
              disclose Personal Information. If you choose not to provide
              mandatory Personal Information, you may still visit parts of the
              Services but you may not be able to access certain options,
              programs, offers, and services that involve our interaction with
              you.
            </li>
            <li>
              <b>Access/Accuracy.</b> To the extent that you do provide us with
              Personal Information, we wish to maintain accurate Personal
              Information. If you would like to delete or correct any of your
              other Personal Information that we may be storing, you may submit
              an access request by sending an email to
              hearourvoicesIL@gmail.com. Your email should include adequate
              details of your request.
            </li>
            <li>
              <b>Links to and Interaction with Third Party Products.</b> The Services
              may enable you to interact with or contain links to other
              third-party websites, mobile software applications and services
              that are not owned or controlled by us (each a <b>“Third Party
              Service”</b>). We are not responsible for the privacy practices or the
              content of such Third Party Services. Please be aware that Third
              Party Services may collect Personal Information from you.
              Accordingly, we encourage you to read the Terms and Conditions and
              Privacy Policy of each Third Party Service that you choose to use
              or interact with.
            </li>
            <li>
              <b>Security.</b> The security of Personal Information is important to us.
              We follow generally accepted industry standards, including the use
              of appropriate administrative, physical, and technical safeguards,
              to protect the Personal Information submitted to us. However, no
              method of transmission over the Internet, or method of electronic
              storage, is 100% secure. Therefore, while we strive to use
              reasonable acceptable means to protect your Personal Information,
              we cannot guarantee its absolute security or confidentiality. If
              you have any questions about security on the Service, you can
              contact us at hearourvoicesIL@gmail.com.
            </li>
            <li>
              <b>Commitment.</b> We are committed to protecting your privacy.
              Protecting your privacy online is an evolving area, and we are
              constantly evolving our Service to meet these demands. If you have
              any comments or questions regarding our Privacy Policy, or your
              Personal Information that we may be storing and using, please
              contact us at hearourvoicesIL@gmail.com.
            </li>
          </ul>
          <b>Last updated: October 25, 2023</b>
        </section>
      </Container>
    </ThemeProvider>
  );
}

export default function PrivacyPage() {
  return <PrivacyContent />;
}
