import * as React from 'react'
import classes from './Donate.module.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import mdTheme from '../../../theme'
import { Helmet } from 'react-helmet'
// import LogoBanner from '../../logobanner/LogoBanner'
// import Banner from '../../banner/Banner'

export default function Donate() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth='lg'>
        <Helmet>
          <script
            src='https://www.jgive.com/embed/embedding-utm.js'
            type='text/javascript'
          />
        </Helmet>
        <section className={`${classes.whoPage}`}>
          <h2>Please Donate</h2>
          <h3>Support our affected families</h3>
          <div className={classes.container}>
            <iframe
              id='jgive-iframe'
              src='https://www.jgive.com/new/en/usd/embeds/2e5cdf21-3bd3-478f-8381-3241fe537302'
              name='JGive iframe'
              title='Hear Our Voices - Resilience Under Fire'
              frameBorder='0'
              scrolling='auto'
              allow='allow-forms; payment; clipboard-write;'
            ></iframe>
            <script src='https://www.jgive.com/embed/embedding-utm.js'></script>
          </div>
          <Container maxWidth='md' sx={{ mt: 2 }}>
            <p style={{ textAlign: 'left' }}>
              Disclaimer: The live event in Los Angeles will be recorded; this
              includes possible stills and videos of the audience. Please
              understand that if you attend the event, you consent to the
              organizers using your digital image in relation to the event. If
              you do not agree, please contact us at{' '}
              <a href='mailto:hearourvoicesIL@gmail.com'>
                hearourvoicesIL@gmail.com
              </a>
            </p>
          </Container>
        </section>
      </Container>
    </ThemeProvider>
  )
}
