import * as React from "react";
import classes from "./Mission.module.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import mdTheme from "../../../theme";
import LogoBanner from "../../logobanner/LogoBanner";
import Banner from "../../banner/Banner";

function MissionContent() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <section className={`${classes.ourMission}`}>
          <h2>Our Mission</h2>
          <div>
            <p>
              We, a dedicated collective of producers and designers, have come
              together as volunteers with a shared purpose: to take action in
              the face of adversity. On this coming Sunday, October 29, we are
              organizing an international musical fundraiser with a singular aim
              - to raise crucial funds for the Israeli victims who endured the
              horrific events of October 7. <br />
              Beyond the financial assistance, our primary mission is to stand
              together in unwavering solidarity with Israel during these trying
              times. Our commitment is a testament to the strength of our shared
              humanity, transcending borders, and echoing a resounding message
              of support for those affected by evil and terror. <br />
              Join us on October 29 in a musical celebration of compassion,
              resilience, and unity as we work to make a meaningful difference
              in the lives of those who need it most.
              <br />
            </p>
          </div>
        </section>
      </Container>
      <Container maxWidth={false} disableGutters>
        <LogoBanner></LogoBanner>
        <Banner></Banner>
      </Container>
    </ThemeProvider>
  );
}
export default function MissionPage() {
  return <MissionContent />;
}
