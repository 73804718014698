import React, { useEffect, useState } from "react";
import classes from "./Contact.module.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import mdTheme from "../../../theme";
import {
  DataGrid,
  GridColDef,
  GridColTypeDef,
  GridRenderEditCellParams,
  GridToolbar,
  useGridApiContext,
} from "@mui/x-data-grid";
import { db } from "../../../data/Db";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";

export interface IContactMessage {
  name: string;
  phone: string;
  email: string;
  message: string;
}

function EditTextarea(props: GridRenderEditCellParams<any, string>) {
  const { id, field, value, colDef, hasFocus } = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback<
    NonNullable<InputBaseProps["onChange"]>
  >(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    },
    [apiRef, field, id]
  );

  return (
    <div style={{ position: "relative", alignSelf: "flex-start" }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{ textarea: { resize: "both" }, width: "100%" }}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

const multilineColumn: GridColTypeDef = {
  type: "string",
  renderEditCell: (params) => <EditTextarea {...params} />,
};

export default function ContactOverview() {
  const logos = ["opera", "opus", "tlvmua", "sunvideo", "meitar", "matan"];

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Full name",
      width: 180,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
    {
      field: "message",
      headerName: "Message",
      sortable: false,
      width: 400,
      editable: true,
      ...multilineColumn,
    },
  ];

  const [contactForms, setContactForms] = useState<any>([]);
  const contactFormsRef = db.collection("contactForm");

  useEffect(() => {
    contactFormsRef.orderBy("name").onSnapshot((snapshot) => {
      const tempData: any[] = [];
      snapshot.forEach((doc) => {
        const data = {
          ...doc.data(),
          id: doc.id,
        };
        tempData.push(data);
      });
      setContactForms(tempData);
    });
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", mt: 15 }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Container className={classes.contactPage}>
            <Box
              sx={{
                height: 400,
                width: "100%",
                backgroundColor: "white",
                marginBottom: 4,
              }}
            >
              <DataGrid
                rowSelection={false}
                rows={contactForms}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
              />
            </Box>
          </Container>
          <Container
            maxWidth={false}
            disableGutters
            sx={{ backgroundColor: "white" }}
          >
            <section className="section2">
              {logos.map((logos, key) => (
                <div key={key} className="logo-container">
                  <img src={`/images/${logos}.svg`}></img>
                </div>
              ))}
            </section>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
