import * as React from 'react'
import classes from './Watch.module.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import mdTheme from '../../../theme'
import LiveStream from '../../livestream/LiveStream'
import Banner from '../../banner/Banner'

function WatchContent() {
  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Container>
        <section className={classes.mainContainer}>
          <h1>Watch the live show</h1>
          <LiveStream></LiveStream>
        </section>
      </Container>
      <Banner></Banner>
    </ThemeProvider>
  )
}

export default function WatchPage() {
  return <WatchContent />
}
