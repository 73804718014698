import HomePage from './components/pages/homepage/HomePage'
import React, { useEffect, useContext } from 'react'
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from 'react-router-dom'
import ResponsiveNavbar from './components/nav/ResponsiveNabvar'
import './App.css'
import LandingPage from './components/pages/lp/LandingPage'
import About from './components/pages/about/About'
import Contact from './components/pages/contact/Contact'
import Privacy from './components/pages/privacy/Privacy'
import Mission from './components/pages/mission/Mission'
import Terms from './components/pages/terms/Terms'
import NotFound from './components/pages/notfound/NotFound'
import ContactOverview from './components/pages/contact/ContactTable'
import Who from './components/pages/who/Who'
import Donate from './components/pages/donate/Donate'
import Watch from './components/pages/watch/Watch'
import { Helmet } from 'react-helmet'

function AppRouter() {
  return (
    <BrowserRouter>
      <div>
        <ResponsiveNavbar>
          <Routes>
            {/* ⬆️ Home route lifted up to the data router */}

            <Route path='/' element={<HomePage />} />
            <Route path='/mission' element={<Mission />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/contactoverview' element={<ContactOverview />} />
            <Route path='/landing' element={<LandingPage />} />
            <Route path='*' element={<NotFound />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/who' element={<Who />} />
            <Route path='/donate' element={<Donate />} />
            <Route path='/watch' element={<Watch />} />
          </Routes>
        </ResponsiveNavbar>
      </div>
    </BrowserRouter>
  )
}

export default AppRouter
