import * as React from 'react'
import classes from './Contact.module.css'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import mdTheme from '../../../theme'
import {
  Alert,
  AlertColor,
  Button,
  FormLabel,
  Snackbar,
  TextField,
} from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { CountryCode } from 'libphonenumber-js'
import { addDoc, collection, documentId } from '@firebase/firestore'
import { db } from '../../../data/Db'
import Banner from '../../banner/Banner'
import LogoBanner from '../../logobanner/LogoBanner'

export interface IContactMessage {
  name: string
  phone: string
  email: string
  message: string
}

export default function Contact() {
  const [phoneValue, setPhoneValue] = React.useState('')
  const defaultCountry: CountryCode = 'US'
  const handlePhoneChange = (newValue) => {
    setPhoneValue(newValue)
  }

  const [nameValue, setNameValue] = React.useState('')
  const handleNameChange = (e) => {
    const newValue: string = e.target.value
    setNameValue(newValue)
  }

  const [emailValue, setEmailValue] = React.useState('')
  const handleEmailChange = (e) => {
    const newValue: string = e.target.value
    setEmailValue(newValue)
  }

  const [messageValue, setMessageValue] = React.useState('')
  const [messageLength, setMessageLength] = React.useState(0)
  const handleMessageChange = (e) => {
    const newValue: string = e.target.value
    setMessageValue(newValue)
    setMessageLength(newValue.length)
  }

  const submitForm = async (e) => {
    e.preventDefault()
    const contactMessage: IContactMessage = {
      name: nameValue,
      phone: phoneValue,
      email: emailValue,
      message: messageValue,
    }
    console.log(contactMessage)

    if (
      !contactMessage.email ||
      !contactMessage.message ||
      !contactMessage.name ||
      !contactMessage.phone
    ) {
      const alertColor: AlertColor = 'warning'
      setSeverity(alertColor)
      setSnackText('Please fill all the fields')
      setOpen(true)
    } else {
      try {
        const docRef = await addDoc(collection(db, 'contactForm'), {
          ...contactMessage,
        })
        console.log('Document written with ID: ', docRef.id)
        const alertColor: AlertColor = 'success'
        setSeverity(alertColor)
        setSnackText('The form has been sent successfully!')
        setOpen(true)
      } catch (e) {
        const alertColor: AlertColor = 'error'
        setSeverity(alertColor)
        setSnackText('Error adding document: ' + e)
        setOpen(true)
        console.error('Error adding document: ', e)
      }
    }
  }

  const [open, setOpen] = React.useState(false)
  const [severity, setSeverity] = React.useState<AlertColor>('success')
  const [snackText, setSnackText] = React.useState('')

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const logos = ['opera', 'opus', 'tlvmua', 'sunvideo', 'meitar', 'matan']

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex', mt: 15 }}>
        <CssBaseline />
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <Container
            disableGutters={false}
            className={classes.contactPage}
            sx={{
              marginBottom: '-18%',
              paddingRight: '32px',
              paddingLeft: '32px',
            }}
          >
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={severity}
                sx={{ width: '100%' }}
              >
                {snackText}
              </Alert>
            </Snackbar>
            <h1>Reach out to us</h1>
            <h2>We'd love to hear from you</h2>
            <Box
              component='form'
              sx={{
                sm: {
                  width: '340px',
                },
                width: '370px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                backgroundColor: 'white',
                padding: 2,
                margin: 1,
                boxShadow:
                  '-15px 22px 54px 0px rgba(96, 97, 112, 0.07), 0px 2px 8px 0px rgba(40, 41, 61, 0.08)',
              }}
              noValidate
              autoComplete='off'
            >
              <TextField
                required
                id='name'
                label='Name'
                variant='outlined'
                value={nameValue}
                onChange={handleNameChange}
              />
              <TextField
                required
                id='email'
                label='Email'
                type='email'
                variant='outlined'
                value={emailValue}
                onChange={handleEmailChange}
              />
              <MuiTelInput
                required
                id='phone'
                defaultCountry={defaultCountry}
                value={phoneValue}
                onChange={handlePhoneChange}
                inputProps={{ maxLength: 18 }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormLabel>We'd like to hear from you</FormLabel>
                <TextField
                  required
                  id='outlined-multiline-static'
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 400 }}
                  value={messageValue}
                  onChange={handleMessageChange}
                />
                <FormLabel>{messageLength}/400 characters</FormLabel>
              </Box>
              <Button
                onClick={submitForm}
                sx={{
                  my: 2,
                  mx: 2,
                  backgroundColor: '#99714B',
                  color: 'white',
                  display: 'block',
                  paddingX: 6,
                  paddingY: 1,
                  border: '2px solid #99714B',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#99714B',
                  },
                }}
              >
                SUBMIT
              </Button>
            </Box>
          </Container>
          <Container
            maxWidth={false}
            disableGutters
            sx={{ backgroundColor: 'white', paddingTop: '18% !important' }}
          ></Container>
          <LogoBanner></LogoBanner>
          <Banner></Banner>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
